import { Formik, Form } from "formik";
import React from "react";
import Modal from "react-modal";
import { TextArea, TextInput } from "../Form/Form";
import * as Yup from "yup";
import { useState } from "react";
import { useAddActivityModal } from "../../shared/hooks/UseModal";
import { useCreateActivity } from "../../shared/hooks/UseActivity";

function AddActivityModal({ client }) {
  const [isAddActivityModalOpen, setIsAddActivityModalOpen] =
    useAddActivityModal();
  const [type_of_activity, setType_of_activity] = useState("phone");
  const [addData] = useCreateActivity();
  return (
    <div>
      <Modal
        isOpen={isAddActivityModalOpen}
        onRequestClose={() => setIsAddActivityModalOpen(false)}
        contentLabel="Modal"
        className="Modal"
        overlayClassName="Overlay"
      >
        <div className="modal-main-header">
          <div className="d-flex justify-content-between">
            <div>
              <div className="modal-main-title">Add Activity</div>
            </div>
            <div>
              <button
                type="button"
                className="btn-close"
                onClick={() => setIsAddActivityModalOpen(false)}
              />
            </div>
          </div>
        </div>
        <div className="modal-man-body">
          <div className="">
            <div className="modal-body">
              <div className="add-activity-modal-content">
                <div className="modal-content-text">
                  Please select the type of activity you want to add
                </div>
              </div>

              <div className="activity-select-box">
                <div className="row">
                  <div className="col-md-3">
                    <div
                      className={`selection-box ${
                        type_of_activity == "phone" && "active-box"
                      }`}
                      onClick={() => setType_of_activity("phone")}
                    >
                      <div className="modal-icon">
                        <div className="phone-icon">
                          <i className="fa fa-phone" />
                        </div>
                      </div>
                      <div> Call</div>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div
                      className={`selection-box ${
                        type_of_activity == "message" && "active-box"
                      }`}
                      onClick={() => setType_of_activity("message")}
                    >
                      <div className="modal-icon">
                        <div className="comment-icon">
                          <i className="fa fa-comment" />
                        </div>
                      </div>
                      <div>Message</div>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div
                      className={`selection-box ${
                        type_of_activity == "meeting" && "active-box"
                      }`}
                      onClick={() => setType_of_activity("meeting")}
                    >
                      <div className="modal-icon">
                        <div className="calendar-icon">
                          <i className="fa fa-calendar" />
                        </div>
                      </div>
                      <div>Meeting</div>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div
                      className={`selection-box ${
                        type_of_activity == "note" && "active-box"
                      }`}
                      onClick={() => setType_of_activity("note")}
                    >
                      <div className="modal-icon">
                        <div className="file-text-icon">
                          <i className="fa fa-file-text" />
                        </div>
                      </div>
                      <div>Note</div>
                    </div>
                  </div>
                </div>
              </div>
              <Formik
                initialValues={{
                  notes: "",
                }}
                validationSchema={Yup.object({
                  //   name: Yup.string().required("Required"),
                })}
                onSubmit={async (values, { setSubmitting, resetForm }) => {
                  setSubmitting(true);
                  values.client = client._id;
                  values.type_of_activity = type_of_activity;
                  await addData(values);
                  setIsAddActivityModalOpen(false);
                  resetForm();
                  setSubmitting(false);
                }}
              >
                {(formik) => {
                  console.log(formik);
                  return (
                    <Form>
                      <div className="row">
                        <div className="col-md-12">
                          <TextArea label="Notes" name="notes" />
                        </div>
                      </div>
                      <div className="footer-section">
                        <button className="main-button" type="submit">
                          <i className="fa fa-plus" /> CREATE ACTIVITY
                        </button>
                      </div>
                    </Form>
                  );
                }}
              </Formik>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default AddActivityModal;
