import React from "react";
import TableNav from "../../components/table-nav/TableNav";
import Header from "../../components/common/Header";
import Navbar from "../../components/common/Navbar";
import { MENU, SIDEBAR_OPTIONS } from "../../shared/enums/client_enum";

function NewLead() {
  return (
    <div>
      <Header />
      <Navbar menu={MENU}></Navbar>
    </div>
  );
}

export default NewLead;
