import React from "react";
import Header from "../../components/common/Header";

function Integration() {
  return (
    <div>
      <Header />
      <section className="mt-160">
        <div className="container integrations-section-box mb-50">
          <div className="row">
            <div className="col-md-6">
              <div className="integrations-box">
                <div className="integration-box-header d-flex">
                  <div className="integration-social-icon">
                    <i className="fa fa-facebook"></i>
                  </div>
                  <div className="social-media-title">Facebook</div>
                </div>

                <div className="integration-box-details">
                  Receive new leads from your Facebook & Instagram Lead Ads into
                  your LLM account.
                </div>

                <div className="d-flex-2">
                  <div className="integration-status">Not Connected</div>
                  <div className="integration-connection-btn">
                    <div className="integration-connection-text pr-12">
                      Connect
                    </div>
                    <div className="integration-connection-icon">
                      <i className="fa fa-arrow-right"></i>
                    </div>
                  </div>
                </div>
              </div>
              <div className="integrations-box">
                <div className="integration-box-header d-flex">
                  <div
                    className="integration-social-icon"
                    style={{
                      border: "1px solid #1b1b1b",
                      backgroundColor: "#1b1b1b",
                    }}
                  >
                    <i className="fa fa-internet-explorer"></i>
                  </div>
                  <div className="social-media-title">Website</div>
                </div>

                <div className="integration-box-details">
                  Receive new leads from your Website Lead Ads into your LLM
                  account.
                </div>

                <div className="d-flex-2">
                  <div className="integration-status">Not Connected</div>
                  <div className="integration-connection-btn">
                    <div className="integration-connection-text pr-12">
                      Configure
                    </div>
                    <div className="integration-connection-icon">
                      <i className="fa fa-arrow-right"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="integrations-box">
                <div className="integration-box-header d-flex">
                  <div
                    className="integration-social-icon"
                    style={{
                      border: "1px solid #FBBC04",
                      backgroundColor: "#FBBC04",
                    }}
                  >
                    <i className="fa fa-google"></i>
                  </div>
                  <div className="social-media-title">
                    Google Ads Lead Forms
                  </div>
                </div>

                <div className="integration-box-details">
                  Receive new leads from your Google Ads Lead Forms Lead Ads
                  into your LLM account.
                </div>

                <div className="d-flex-2">
                  <div className="integration-status">Not Connected</div>
                  <div className="integration-connection-btn">
                    <div className="integration-connection-text pr-12">
                      Configure
                    </div>
                    <div className="integration-connection-icon">
                      <i className="fa fa-arrow-right"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Integration;
