import React from "react";

import ClientCard from "../client/ClientCard";
import ClientInfo from "../client/ClientInfo";
import { useAllClients } from "../../shared/hooks/UseClient";
import { useState } from "react";
import AssignGroupModal from "../modals/AssignGroupModal";
import { useEffect } from "react";
import EditClientModal from "../modals/EditClientModal";
import AddActivityModal from "../modals/AddActivityModal";
import Skeleton from "react-loading-skeleton";
import Pagination from "../common/Pagination";

function MainTable() {
  const [data] = useAllClients();
  const { clients, clients_loading, total_clients, pages } = data;
  const [activeClient, setActiveClient] = useState(null);
  useEffect(() => {
    if (clients && activeClient) {
      const filteredData = clients.filter(
        (item) => item._id == activeClient._id
      );
      if (filteredData && filteredData.length > 0) {
        setActiveClient(filteredData[0]);
      }
    }
  }, [clients]);
  return (
    <div>
      <section className="pt-17">
        <div className="container-fluid">
          <div className="row">
            <div className="col-3">
              <div className="sidebar">
                {!clients_loading ? (
                  clients &&
                  clients.map((item) => {
                    return (
                      <ClientCard
                        active={activeClient && activeClient._id == item._id}
                        client={item}
                        setActiveClient={setActiveClient}
                      />
                    );
                  })
                ) : (
                  <div>
                    <Skeleton height={180} />
                    <Skeleton height={180} />
                    <Skeleton height={180} />
                    <Skeleton height={180} />
                    <Skeleton height={180} />
                  </div>
                )}
              </div>
              <div className="pagination-clients">
                <Pagination
                  pages={pages}
                  count={total_clients}
                  loading={clients_loading}
                />
              </div>
            </div>
            <div className="col-9">
              {activeClient ? (
                <div className="sticky-section">
                  <ClientInfo client={activeClient} />
                  <AssignGroupModal client={activeClient} />
                  <EditClientModal client={activeClient} />
                  <AddActivityModal client={activeClient} />
                </div>
              ) : (
                <div>
                  {!clients_loading && (
                    <div>
                      <h4> Select a Contact to view details </h4>
                      <p>
                        {" "}
                        Please choose form left side to view Contact details{" "}
                      </p>
                    </div>
                  )}
                </div>
              )}
              {clients_loading && (
                <div className="row">
                  <div className="col-8">
                    <Skeleton height={400} />
                  </div>
                  <div className="col-4">
                    <Skeleton height={480} />
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default MainTable;
