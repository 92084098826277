import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
// import Footer from "../common/Footer";
import Header from "../../components/common/Header";

const PageNotFound = () => {
  return (
    <>
      <Header />
      <div className="error-section">
        <div className="container">
          <div className="row">
            <div className="error_form">
              <h1
                data-aos="fade-up"
                data-aos-delay={0}
                className="aos-init aos-animate"
              >
                404
              </h1>
              <h4
                data-aos="fade-up"
                data-aos-delay={200}
                className="aos-init aos-animate"
              >
                Opps! PAGE NOT BE FOUND
              </h4>
              <p
                data-aos="fade-up"
                data-aos-delay={400}
                className="aos-init aos-animate"
              >
                Sorry but the page you are looking for does not exist, have been
                <br /> removed, name changed or is temporarily unavailable.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(PageNotFound);
