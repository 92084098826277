import { Form, Formik } from "formik";
import { TextInput } from "../../components/Form/Form";
import * as Yup from "yup";
import React, { useRef } from "react";
import Header from "../../components/common/Header";
import { useDispatch, useSelector } from "react-redux";
import { logout, updateProfile } from "../../store/actions/auth";
import { Link, useHistory } from "react-router-dom";
import api from "../../domain/api";
import { URI } from "../../domain/constant";
import { useState } from "react";
import Skeleton from "react-loading-skeleton";
function Profile() {
  const fileInput = useRef(null);
  const history = useHistory();
  const dispatch = useDispatch();
  const [uploadingImage, setUploadingImage] = useState(false);
  const data = useSelector((state) => state.auth);
  console.log("DATA", data);
  const { user } = data;
  const handleFileInput = async (e) => {
    // handle validations
    // onFileSelect(e.target.files[0]);
    setUploadingImage(true);
    const formData = new FormData();
    formData.append("image", e.target.files[0]);
    const { data } = await api.post("/upload/", formData);
    await dispatch(updateProfile({ profile_image: data }));
    setUploadingImage(false);
  };
  return (
    <div>
      <Header />
      {user && (
        <section className="mt-120">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="d-flex-3">
                  <div className="profile-box">
                    <div className="user-profile-img">
                      <div className="profile-img">
                        {!uploadingImage ? (
                          <img
                            src={
                              user && user.profile_image
                                ? `${URI}${user.profile_image}`
                                : "assets/images/placeholder.png"
                            }
                            alt=""
                          />
                        ) : (
                          <Skeleton height={50} circle={true} width={50} />
                        )}
                      </div>
                      <div className="text-center">
                        <input
                          ref={fileInput}
                          type="file"
                          onChange={handleFileInput}
                          style={{ display: "none" }}
                        />
                        <a
                          className="profile-text"
                          onClick={(e) =>
                            fileInput.current && fileInput.current.click()
                          }
                        >
                          <i className="fa fa-edit"></i> Edit Profile
                        </a>
                      </div>
                    </div>
                    <div className="profile-form">
                      <Formik
                        initialValues={{
                          name: user.name,
                          email: user.email,
                          phone: user.phone,
                          company_name: user.company_name,
                        }}
                        validationSchema={Yup.object({
                          name: Yup.string().required("Required"),
                          email: Yup.string().required("Required"),
                          phone: Yup.string().required("Required"),
                          company_name: Yup.string().required("Required"),
                        })}
                        onSubmit={async (
                          values,
                          { setSubmitting, resetForm }
                        ) => {
                          setSubmitting(true);
                          await dispatch(updateProfile(values));
                          history.push("/dashboard");
                          resetForm();
                          setSubmitting(false);
                        }}
                      >
                        {(formik) => {
                          console.log(formik);
                          return (
                            <Form>
                              <div className="row">
                                <div className="col-md-12">
                                  <TextInput
                                    label="Name"
                                    name="name"
                                    type="text"
                                  />
                                </div>
                                <div className="col-md-12">
                                  <TextInput
                                    label="Phone"
                                    name="phone"
                                    type="text"
                                  />
                                </div>
                                <div className="col-md-12">
                                  <TextInput
                                    label="Email"
                                    name="email"
                                    type="text"
                                  />
                                </div>
                                <div className="col-md-12">
                                  <TextInput
                                    label="Company Name"
                                    name="company_name"
                                    type="text"
                                  />
                                </div>
                                <div className="pt-20">
                                  <button
                                    type="submit"
                                    className=" button-31 p"
                                  >
                                    <i className="fa fa-save"></i> Update
                                  </button>
                                </div>
                                <div className="pt-20">
                                  <Link
                                    to={"/change-password"}
                                    className="btn     btn-block btn-warning"
                                    style={{ width: "100%" }}
                                  >
                                    <i className="fa fa-save"></i> Change
                                    Password
                                  </Link>
                                </div>
                                <div className="pt-20">
                                  <button
                                    className="btn     btn-block btn-danger"
                                    style={{ width: "100%" }}
                                    onClick={() => dispatch(logout())}
                                  >
                                    <i className="fa fa-sign-out"></i> Logout
                                  </button>
                                </div>
                              </div>
                            </Form>
                          );
                        }}
                      </Formik>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      )}
    </div>
  );
}

export default Profile;
