import api from "../../domain/api";
import {
  GET_GROUPS_STATED,
  GET_GROUPS,
  GET_GROUPS_ENDED,
  ADD_GROUP_STATED,
  ADD_GROUP,
  ADD_GROUP_ENDED,
  EDIT_GROUP_STATED,
  EDIT_GROUP,
  EDIT_GROUP_ENDED,
  GET_GROUP_STATED,
  GET_GROUP,
  GET_GROUP_ENDED,
  GET_ALL_GROUPS_STATED,
  GET_ALL_GROUPS,
  GET_ALL_GROUPS_ENDED,
} from "../types/group_type";
import * as qs from "qs";
import { handleError } from "../../shared/handleError";
import { setAlert } from "./alert";

export const addGroup = (formData) => async (dispatch, getState) => {
  try {
    dispatch({
      type: ADD_GROUP_STATED,
    });
    const { data } = await api.post(`/groups`, formData);
    dispatch({
      type: ADD_GROUP,
      payload: data,
    });
    dispatch({
      type: ADD_GROUP_ENDED,
    });
  } catch (error) {
    dispatch({
      type: ADD_GROUP_ENDED,
    });
    dispatch(handleErrorLocal(error));
    dispatch(handleError(error));
  }
};
export const getGroups =
  ({ pageNumber = "" }) =>
  async (dispatch) => {
    try {
      dispatch({
        type: GET_GROUPS_STATED,
      });
      const queryParams = qs.parse(window.location.search.replace("?", ""));
      const query = qs.stringify(queryParams, {
        encodeValuesOnly: true, // prettify url
      });

      const { data } = await api.get(
        `/groups?&pageNumber=${pageNumber}&${query}`
      );

      dispatch({
        type: GET_GROUPS,
        payload: data,
      });
      dispatch({
        type: GET_GROUPS_ENDED,
      });
    } catch (error) {
      dispatch({
        type: GET_GROUPS_ENDED,
      });
      dispatch(handleErrorLocal(error));
      dispatch(handleError(error));
    }
  };
export const getGroup = (id) => async (dispatch) => {
  try {
    dispatch({
      type: GET_GROUP_STATED,
    });
    const { data } = await api.get(`/groups/${id}`);

    dispatch({
      type: GET_GROUP,
      payload: data,
    });
    dispatch({
      type: GET_GROUP_ENDED,
    });
  } catch (error) {
    dispatch({
      type: GET_GROUP_STATED,
    });
    dispatch(handleErrorLocal(error));
    dispatch(handleError(error));
  }
};
export const editGroup = (id, formData) => async (dispatch) => {
  try {
    dispatch({
      type: EDIT_GROUP_STATED,
    });
    const { data } = await api.put(`/groups/${id}`, formData);
    dispatch({
      type: EDIT_GROUP,
      payload: data,
    });
    dispatch({
      type: EDIT_GROUP_ENDED,
    });
  } catch (error) {
    dispatch({
      type: EDIT_GROUP_ENDED,
    });
    dispatch(handleErrorLocal(error));
    dispatch(handleError(error));
  }
};
export const deleteGroup = (id) => async (dispatch) => {
  try {
    const { data } = await api.delete(`/groups/${id}`);
    dispatch({
      type: EDIT_GROUP,
      payload: data,
    });
    dispatch({
      type: EDIT_GROUP_ENDED,
    });
  } catch (error) {
    dispatch(handleErrorLocal(error));
    dispatch(handleError(error));
  }
};
export const getAllGroups =
  ({ term, value }) =>
  async (dispatch) => {
    try {
      dispatch({
        type: GET_ALL_GROUPS_STATED,
      });
      let url = "";
      if (term && value) {
        url = `&term=${term}&value=${value}`;
      }

      const { data } = await api.get(`/groups/all?${url}`);

      dispatch({
        type: GET_ALL_GROUPS,
        payload: data,
      });
      dispatch({
        type: GET_ALL_GROUPS_ENDED,
      });
    } catch (error) {
      dispatch({
        type: GET_ALL_GROUPS_ENDED,
      });
      dispatch(handleErrorLocal(error));
      dispatch(handleError(error));
    }
  };

export const handleErrorLocal = () => async (dispatch) => {};
