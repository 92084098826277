import { Formik, Form } from "formik";
import React from "react";
import Modal from "react-modal";
import { SelectBox, TextArea, TextInput } from "../Form/Form";
import * as Yup from "yup";
import { useState } from "react";
import { useCreateClient } from "../../shared/hooks/UseClient";
import { useAddClientModal } from "../../shared/hooks/UseModal";

function AddNewClientModal({}) {
  const [data, addData] = useCreateClient();
  const [isAddClientModalOpen, setAddClientModalIsOpen] = useAddClientModal();
  return (
    <div>
      <Modal
        isOpen={isAddClientModalOpen}
        onRequestClose={() => setAddClientModalIsOpen(false)}
        contentLabel="Modal"
        className="Modal"
        overlayClassName="Overlay"
      >
        <div className="modal-main-header">
          <div className="d-flex justify-content-between">
            <div>
              <div className="modal-main-title">Add Client</div>
            </div>
            <div>
              <button
                type="button"
                className="btn-close"
                onClick={() => setAddClientModalIsOpen(false)}
              />
            </div>
          </div>
        </div>
        <div className="modal-man-body">
          <div>
            <div>
              <div>
                <Formik
                  initialValues={{
                    name: "",
                    display_name: "",
                    phone: "",
                    whatsapp_no: "",
                    notes: "",
                    source: "",
                  }}
                  validationSchema={Yup.object({
                    name: Yup.string().required("Required"),
                    phone: Yup.string().required("Required"),
                  })}
                  onSubmit={async (values, { setSubmitting, resetForm }) => {
                    setSubmitting(true);
                    await addData(values);
                    setAddClientModalIsOpen(false);
                    resetForm();
                    setSubmitting(false);
                  }}
                >
                  {(formik) => {
                    console.log(formik);
                    return (
                      <Form>
                        <div>
                          <div className="modal-body client-add-area">
                            <div className="row">
                              <div className="col-md-6">
                                <TextInput
                                  label="Client Name"
                                  name="name"
                                  type="text"
                                />
                              </div>
                              <div className="col-md-6">
                                <TextInput
                                  label="Display Name"
                                  name="display_name"
                                  type="text"
                                />
                              </div>
                              <div className="col-md-6">
                                <TextInput
                                  label="Phone"
                                  name="phone"
                                  type="text"
                                />
                              </div>
                              <div className="col-md-6">
                                <TextInput
                                  label="WhatsApp No"
                                  name="whatsapp_no"
                                  type="text"
                                />
                              </div>
                              <div className="col-md-6">
                                <TextInput
                                  label="Email"
                                  name="email"
                                  type="text"
                                />
                              </div>

                              <div className="col-md-6">
                                <SelectBox label="Source" name="source">
                                  <option value=""> --none-- </option>
                                  <option value="facebook">Facebook</option>
                                  <option value="google">Google</option>
                                  <option value="instagram">Instagram</option>
                                  <option value="website">Website</option>
                                  <option value="walk-in">Walk In</option>
                                  <option value="influencer">Influencer</option>
                                </SelectBox>
                              </div>
                              <div className="col-md-12">
                                <TextArea
                                  label="Notes"
                                  name="notes"
                                  type="text"
                                />
                              </div>
                            </div>
                          </div>
                          <div className="footer-section">
                            <button type="submit" className="main-button">
                              <i className="fa fa-plus" /> CREATE NEW CLIENT
                            </button>
                          </div>
                        </div>
                      </Form>
                    );
                  }}
                </Formik>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default AddNewClientModal;
