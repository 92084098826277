import { useSelector, useDispatch } from "react-redux";
import {
  handleAddGroupModal,
  handleAllGroupModal,
  handleAddClientModal,
  handleEditClientModal,
  handleDeleteGroupModal,
  handleAssignGroupClientModal,
  handleAddActivityModal,
  handleEditActivityModal,
  handleSidebarFilterModal,
} from "../../store/actions/modal_action";
export const useAddGroupModal = () => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.modal);
  const { is_add_group_modal_open } = data;
  const changeStatus = (status) => {
    dispatch(handleAddGroupModal(status));
  };
  return [is_add_group_modal_open, changeStatus];
};
export const useAllGroupModal = () => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.modal);
  const { is_all_group_modal_open } = data;
  const changeStatus = (status) => {
    dispatch(handleAllGroupModal(status));
  };
  return [is_all_group_modal_open, changeStatus];
};
export const useDeleteGroupModal = () => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.modal);
  const { is_delete_group_modal_open } = data;
  const changeStatus = (status) => {
    dispatch(handleDeleteGroupModal(status));
  };
  return [is_delete_group_modal_open, changeStatus];
};
export const useAddClientModal = () => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.modal);
  const { is_add_client_modal_open } = data;
  const changeStatus = (status) => {
    dispatch(handleAddClientModal(status));
  };
  return [is_add_client_modal_open, changeStatus];
};
export const useEditClientModal = () => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.modal);
  const { is_edit_client_modal_open } = data;
  const changeStatus = (status) => {
    dispatch(handleEditClientModal(status));
  };
  return [is_edit_client_modal_open, changeStatus];
};
export const useAssignGroupClientModal = () => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.modal);
  const { is_assign_group_client_modal_open } = data;
  const changeStatus = (status) => {
    dispatch(handleAssignGroupClientModal(status));
  };
  return [is_assign_group_client_modal_open, changeStatus];
};

export const useAddActivityModal = () => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.modal);
  const { is_add_activity_modal_open } = data;
  const changeStatus = (status) => {
    dispatch(handleAddActivityModal(status));
  };
  return [is_add_activity_modal_open, changeStatus];
};
export const useEditActivityModal = () => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.modal);
  const { is_edit_activity_modal_open } = data;
  const changeStatus = (status) => {
    dispatch(handleEditActivityModal(status));
  };
  return [is_edit_activity_modal_open, changeStatus];
};

export const useHandleSidebarFilterModal = () => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.modal);
  const { is_sidebar_filter_modal_open } = data;
  const changeStatus = (status) => {
    dispatch(handleSidebarFilterModal(status));
  };
  return [is_sidebar_filter_modal_open, changeStatus];
};
