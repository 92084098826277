import React, { useState } from "react";
import TableNav from "../../components/table-nav/TableNav";
import Header from "../../components/common/Header";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { Link } from "react-router-dom";

function Team() {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [showModal, setShowModal] = useState(false);

  const handleCloseModal = () => setShowModal(false);
  const handleShowModal = () => setShowModal(true);

  return (
    <div>
      <Header />
      <TableNav
        addButtonTitle={`Invite New Members`}
        nav1={"Team Members"}
        nav2={"Invite New Member"}
        link1="/team"
        link2="/lead-assigned"
      />

      <section className="teams mt-15">
        <div className="container-fluid">
          <div className="total-active-members">
            <div className="active-members">2 ACTIVE TEAM ACCOUNTS</div>
            <div
              variant="primary"
              onClick={handleShow}
              className="view-details-btn"
            >
              View billing details
              <div className="billing-details-icon">
                <div className="arrow-right-icon">
                  <i className="fa fa-long-arrow-right"></i>
                </div>
              </div>
            </div>
            <Modal
              show={show}
              onHide={handleClose}
              backdrop="static"
              keyboard={false}
            >
              <Modal.Header closeButton>
                <Modal.Title>Account Billing Details</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className="billing-modal-main">
                  <div className="modal-section-title">
                    LLM Subscription for 7 Accounts
                  </div>
                  <div className="modal-section-title-pricing">
                    $660.00 / year
                  </div>

                  <div className="modal-main-content">
                    Team accounts are charged while they are active. Billing
                    starts once a team member logs in for the first time to
                    activate their account, and stops when the account is
                    deactivated. You will not be charged for team accounts that
                    have been invited but not yet activated.
                  </div>

                  <table class="table billing-table">
                    <thead>
                      <tr>
                        <th scope="col">Item</th>
                        <th scope="col">QTY</th>
                        <th scope="col">Unit Price</th>
                        <th scope="col">Amount</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>LLM Subscription</td>
                        <td>1</td>
                        <td>$120.00</td>
                        <td>$120.00</td>
                      </tr>
                      <tr>
                        <td>Annual Subscription</td>
                        <td></td>
                        <td>Total</td>
                        <td>$660.00</td>
                      </tr>
                    </tbody>
                  </table>
                  <div className="billing-modal-btn">
                    <button className="btn btn-primary">OK</button>
                  </div>
                </div>
              </Modal.Body>
            </Modal>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="team-members-table fs-14">
                <table class="table table-hover">
                  <thead>
                    <tr>
                      <th scope="col">S No.</th>
                      <th scope="col">Name</th>
                      <th scope="col">Status</th>
                      <th scope="col">Permissions</th>
                      <th scope="col">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td scope="row">
                        <div className="table-serial-no">1.</div>
                      </td>
                      <td>
                        Anandita
                        <div>sumit@luhaifdigitech.com</div>
                      </td>
                      <td>
                        <div className="table-status-icon-text">
                          <div className="table-status">
                            <i className="fa fa-check"></i>
                            <span className="pl-3">Active</span>
                          </div>
                        </div>
                      </td>
                      <td>
                        View Clients (Self, Unassigned)
                        <div>Add & Edit Content, Add & Edit Groups</div>
                      </td>
                      <td>
                        <div className="vertical-dots-icons">
                          <i className="fa fa-ellipsis-v"></i>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td scope="row">
                        <div className="table-serial-no">1.</div>
                      </td>
                      <td>
                        Mamta
                        <div>m@luhaifdigitech.com</div>
                      </td>
                      <td>
                        <div className="table-status-icon-text">
                          <div className="table-status">
                            <i className="fa fa-check"></i>
                            <span className="pl-3">Active</span>
                          </div>
                        </div>
                      </td>
                      <td>
                        View Clients (Self, Unassigned)
                        <div>Add & Edit Content, Add & Edit Groups</div>
                      </td>
                      <td>
                        <div className="vertical-dots-icons">
                          <i className="fa fa-ellipsis-v"></i>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td scope="row">
                        <div className="table-serial-no">1.</div>
                      </td>
                      <td>
                        Taiban
                        <div>tai@luhaifdigitech.com</div>
                      </td>
                      <td>
                        <div className="table-status-icon-text">
                          <div className="table-status-deactivated">
                            <i className="fa fa-times"></i>
                            <span className="pl-3">Deactivated</span>
                          </div>
                        </div>
                      </td>
                      <td>
                        View Clients (Self, Unassigned)
                        <div>Add & Edit Content, Add & Edit Groups</div>
                      </td>
                      <td>
                        <div className="vertical-dots-icons">
                          <i className="fa fa-ellipsis-v"></i>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className="teams-table-footer-text">
        To learn more about using <span>LLM</span> with your team, check out our
        Team Features Help Guide
      </div>
    </div>
  );
}

export default Team;
