import React from "react";
import { Link } from "react-router-dom";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { TextInput } from "../../components/Form/Form";
import { useDispatch } from "react-redux";
import { login } from "../../store/actions/auth";
function Signin() {
  const dispatch = useDispatch();
  return (
    <div>
      <section>
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="company-logo p-25">
                <img src="/logo.png" alt="luhaif-logo" />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="signup-section">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="signup-box">
                <div className="signin-form-content">
                  <div className="signup-form-heading">
                    <h3>
                      Sign In to <span>LEDSAK</span>
                    </h3>
                  </div>
                  <div className="signup-form-text">
                    Please sign in with your registered email address
                  </div>
                </div>
                <div className="signup-form">
                  <Formik
                    initialValues={{
                      email: "",
                      password: "",
                    }}
                    validationSchema={Yup.object({
                      email: Yup.string().required("Required"),
                      password: Yup.string().required("Required"),
                    })}
                    onSubmit={async (values, { setSubmitting, resetForm }) => {
                      setSubmitting(true);
                      dispatch(login(values));
                      resetForm();
                      setSubmitting(false);
                    }}
                  >
                    {(formik) => {
                      console.log(formik);
                      return (
                        <Form>
                          <div className="row">
                            <div className="form-group pb-12">
                              <TextInput
                                label="Email"
                                name="email"
                                type="text"
                              />
                            </div>
                            <div className="form-group pb-12">
                              <TextInput
                                label="Password"
                                name="password"
                                type="password"
                              />
                            </div>
                          </div>
                          <div className="pt-20">
                            <button type="submit" className="button-31 p">
                              Submit
                            </button>
                          </div>
                        </Form>
                      );
                    }}
                  </Formik>
                </div>
                <div className="signup-box-footer-text">
                  Don’t have an account yet? <Link to="/signup">SIGN UP</Link>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="signup-page-side-img">
                <img
                  src="/assets/images/v955-audi-instagram-10-ps.jpg"
                  alt="img"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Signin;
