import {
  ADD_GROUP_MODAL,
  ALL_GROUP_MODAL,
  DELETE_GROUP_MODAL,
  ADD_CLIENT_MODAL,
  EDIT_CLIENT_MODAL,
  ASSIGN_GROUP_CLIENT_MODAL,
  ADD_ACTIVITY_MODAL,
  EDIT_ACTIVITY_MODAL,
  SIDEBAR_FILTERS_MODAL,
} from "../types/modal_type";
const initialState = {
  is_add_group_modal_open: false,
  is_all_group_modal_open: false,
  is_delete_group_modal_open: false,
  is_add_client_modal_open: false,
  is_edit_client_modal_open: false,
  is_assign_group_client_modal_open: false,
  is_add_activity_modal_open: false,
  is_edit_activity_modal_open: false,
  is_sidebar_filter_modal_open: false,
};

export const modal_reducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case ADD_GROUP_MODAL:
      return {
        ...state,
        is_add_group_modal_open: payload,
      };
    case ALL_GROUP_MODAL:
      return {
        ...state,
        is_all_group_modal_open: payload,
      };
    case DELETE_GROUP_MODAL:
      return {
        ...state,
        is_delete_group_modal_open: payload,
      };
    case ADD_CLIENT_MODAL:
      return {
        ...state,
        is_add_client_modal_open: payload,
      };
    case EDIT_CLIENT_MODAL:
      return {
        ...state,
        is_edit_client_modal_open: payload,
      };
    case ASSIGN_GROUP_CLIENT_MODAL:
      return {
        ...state,
        is_assign_group_client_modal_open: payload,
      };

    case ADD_ACTIVITY_MODAL:
      return {
        ...state,
        is_add_activity_modal_open: payload,
      };
    case EDIT_ACTIVITY_MODAL:
      return {
        ...state,
        is_edit_activity_modal_open: payload,
      };
    case SIDEBAR_FILTERS_MODAL:
      return {
        ...state,
        is_sidebar_filter_modal_open: payload,
      };

    default:
      return state;
  }
};
