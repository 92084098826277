import React from "react";
import AddGroupModal from "../modals/AddGroupModal";
import AddNewClient from "../modals/AddNewClientModal";
import GroupModal from "../modals/GroupModal";

function Footer() {
  return (
    <div>
      <GroupModal />
      <AddGroupModal />
      <AddNewClient />
    </div>
  );
}

export default Footer;
