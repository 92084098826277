import React from "react";
import TableNav from "../../components/table-nav/TableNav";
import Header from "../../components/common/Header";

function Content() {
  return (
    <div>
      <Header />
      <TableNav
        addButtonTitle={`New Message`}
        nav1={"Messages"}
        nav2={"Files"}
        nav3={"Pages"}
      />
      <section className="pt-17">
        <div className="container-fluid">
          <div className="row">
            <div className="col-3">
              <div className="contact-card active">
                <div className="contact-card-details">
                  <div className="person-card">
                    <div className="person-name">First Contact Message</div>
                  </div>
                  <div className="contact-info leads-card-info-active">
                    Hi @clientName , Welcome to Our Clinic, How may I help you
                  </div>
                </div>
                <div className="date-details">
                  <div className="card-date"> 10 Sept </div>
                  <div className="contact-icon">
                    <i className="fa fa-envelope" />
                  </div>
                </div>
              </div>
              <div className="contact-card ">
                <div className="contact-card-details">
                  <div className="person-card">
                    <div className="person-name">First Contact Message</div>
                  </div>
                  <div className="contact-info leads-card-info-active">
                    Hi @clientName , Welcome to Our Clinic, How may I help you
                  </div>
                </div>
                <div className="date-details">
                  <div className="card-date"> 10 Sept </div>
                  <div className="contact-icon">
                    <i className="fa fa-envelope" />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-9">
              <div className="row">
                <div className="col-8">
                  <div className="details-card-section border-shadow">
                    <div className="section-title">
                      <h4>Preview:</h4>
                    </div>
                    <div className="row">
                      <div className="col-md-12">
                        <div className="preview-content">
                          <div className="content-title">
                            <p> Hi @clientName,</p>
                          </div>

                          <div className="content-description">
                            <p>
                              Thank you for your interest in ACME Residences.
                              I’ll send over an eBrochure shortly with more
                              details on the project
                            </p>

                            <p>
                              Can I check if you’re looking to buy for your own
                              stay or investment purposes?
                            </p>
                          </div>

                          <div className="content-signature">
                            <div className="signature-email">
                              <p>ajitawadhiya@gmail.com</p>
                            </div>
                            <div className="signature-name">
                              <p>Ajit Awadhiya</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-4">
                  <div className="details-card-section bg-off-white-1 border-shadow">
                    <div className="section-title">
                      <h4>Sharing History:</h4>
                    </div>
                    <div className="row">
                      <div className="col-md-12">
                        <div className="sharing-history-box">
                          <div className="sharing-history-content">
                            <div className="sharing-history-icon">
                              <div className="archive-box-icon">
                                <i class="fa fa-archive"></i>
                              </div>
                            </div>

                            <div className="sharing-history-title">
                              <p> You haven't sent this message</p>

                              <p>
                                Use the 'Quick Response' feature in the Privyr
                                mobile app to send auto-personalised versions of
                                this message to your leads and clients.
                              </p>
                            </div>

                            <div className="sharing-history-footer-text">
                              <div className="learn-more-text">LEARN MORE</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-8">
                  <div className="details-card-section bg-off-white-1 border-shadow">
                    <div className="section-title">
                      <h4>Timeline:</h4>
                    </div>
                    <div className="row">
                      <div className="col-12">
                        <div
                          className="details-card-details"
                          type="button"
                          data-bs-toggle="modal"
                          data-bs-target="#exampleModal"
                        >
                          <div className="timeline-card">
                            <div className="timeline-icon">
                              <div className="phone-icon purple-icon">
                                <i className="fa fa-plus" />
                              </div>
                            </div>
                            <div className="details-title pl-20">
                              Add Activity
                            </div>
                            {/* Modal */}
                            <div
                              className="modal fade"
                              id="exampleModal"
                              tabIndex={-1}
                              aria-labelledby="exampleModalLabel"
                              aria-hidden="true"
                            >
                              <div className="modal-dialog">
                                <div className="modal-content">
                                  <div className="modal-header">
                                    <h1
                                      className="modal-title fs-5"
                                      id="exampleModalLabel modal-title"
                                    >
                                      Add Activity
                                    </h1>
                                    <span className="modal-client-name">
                                      with Shubbu
                                    </span>
                                    <button
                                      type="button"
                                      className="btn-close"
                                      data-bs-dismiss="modal"
                                      aria-label="Close"
                                    />
                                  </div>
                                  <div className="modal-body">
                                    <div className="add-activity-modal-content">
                                      <div className="modal-content-text">
                                        Please select the type of activity you
                                        want to add
                                      </div>
                                    </div>
                                    <div className="activity-select-box">
                                      <div className="row">
                                        <div className="col-md-3">
                                          <div className="selection-box">
                                            <div className="modal-icon">
                                              <div className="phone-icon">
                                                <i className="fa fa-phone" />
                                              </div>
                                            </div>
                                            <div>Phone Call</div>
                                          </div>
                                        </div>
                                        <div className="col-md-3">
                                          <div className="selection-box">
                                            <div className="modal-icon">
                                              <div className="comment-icon">
                                                <i className="fa fa-comment" />
                                              </div>
                                            </div>
                                            <div>Message</div>
                                          </div>
                                        </div>
                                        <div className="col-md-3">
                                          <div className="selection-box">
                                            <div className="modal-icon">
                                              <div className="calendar-icon">
                                                <i className="fa fa-calendar" />
                                              </div>
                                            </div>
                                            <div>Meeting</div>
                                          </div>
                                        </div>
                                        <div className="col-md-3">
                                          <div className="selection-box">
                                            <div className="modal-icon">
                                              <div className="file-text-icon">
                                                <i className="fa fa-file-text" />
                                              </div>
                                            </div>
                                            <div>Note</div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  {/* <div class="modal-footer">
<button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
<button type="button" class="btn btn-primary">Save changes</button>
</div> */}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <hr />
                        <div className="details-card-details mt-16">
                          <div className="timeline-card">
                            <div className="timeline-icon">
                              <div className="phone-icon purple-icon">
                                <i className="fa fa-phone" />
                              </div>
                            </div>
                            <div className="d-flex">
                              <div className="timeline-info-date pl-20">
                                Nov 29, 2022
                              </div>
                              <div className="timeline-info-time pl-5">
                                04:32 PM
                              </div>
                            </div>
                          </div>
                          <div className="details-title pl-50">Phone Call</div>
                        </div>
                        <hr />
                        <div className="details-card-details mt-16">
                          <div className="timeline-card">
                            <div className="timeline-icon">
                              <div className="phone-icon purple-icon">
                                <i className="fa fa-check" />
                              </div>
                            </div>
                            <div className="d-flex">
                              <div className="timeline-info-date pl-20">
                                Nov 29, 2022
                              </div>
                              <div className="timeline-info-time pl-5">
                                04:32 PM
                              </div>
                            </div>
                          </div>
                          <div className="details-title pl-50">
                            First Response Logged in 1 minute
                          </div>
                          <div className="timeline-details-2 pl-50">
                            Added Phone Call Activity
                          </div>
                        </div>
                        <hr />
                        <div className="details-card-details mt-16">
                          <div className="timeline-card">
                            <div className="timeline-icon">
                              <div className="phone-icon purple-icon">
                                <i className="fa fa-user-plus" />
                              </div>
                            </div>
                            <div className="d-flex">
                              <div className="timeline-info-date pl-20">
                                Nov 29, 2022
                              </div>
                              <div className="timeline-info-time pl-5">
                                04:30 PM
                              </div>
                            </div>
                          </div>
                          <div className="details-title pl-50">
                            Client Added to Privyr
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Content;
