import { Formik, Form } from "formik";
import React from "react";
import Modal from "react-modal";
import { TextInput } from "../Form/Form";
import * as Yup from "yup";
import { useState } from "react";
import { useCreateGroup } from "../../shared/hooks/UseGroup";
import {
  useAllGroupModal,
  useAddGroupModal,
} from "../../shared/hooks/UseModal";
const colors = [
  {
    id: 1,
    name: "color1",
    code: "#1ecd8e",
  },
  {
    id: 2,
    name: "color2",
    code: "#ffda18",
  },
  {
    id: 3,
    name: "color3",
    code: "#f21423",
  },
  {
    id: 4,
    name: "color4",
    code: "#0d4c92",
  },
  {
    id: 5,
    name: "color5",
    code: "#a58def",
  },
  {
    id: 6,
    name: "color6",
    code: "#75ddc9",
  },
  {
    id: 7,
    name: "color7",
    code: "#ff8787",
  },
  {
    id: 8,
    name: "color8",
    code: "#e669bc",
  },
  {
    id: 9,
    name: "color9",
    code: "#18ad28",
  },
  {
    id: 10,
    name: "color10",
    code: "#bc4ed6",
  },
];

function AddGroupModal() {
  const [activeColor, setActiveColor] = useState(1);
  const [addGroupData, group_data] = useCreateGroup();
  const { add_group_loading } = group_data;
  const [isGroupModalOpen, setGroupModalIsOpen] = useAllGroupModal();
  const [isAddGroupModalOpen, setAddGroupModalIsOpen] = useAddGroupModal();
  return (
    <div>
      <Modal
        isOpen={isAddGroupModalOpen}
        onRequestClose={() => setAddGroupModalIsOpen(false)}
        contentLabel="Modal"
        className="Modal"
        overlayClassName="Overlay"
      >
        <div className="modal-main-header">
          <div className="d-flex justify-content-between">
            <div>
              <div className="modal-main-title">Add Group</div>
            </div>
            <div>
              <button
                type="button"
                className="btn-close"
                onClick={() => setAddGroupModalIsOpen(false)}
              />
            </div>
          </div>
        </div>
        <div className="modal-man-body">
          {!add_group_loading ? (
            <div>
              <div>
                <div>
                  <Formik
                    initialValues={{
                      name: "",
                    }}
                    validationSchema={Yup.object({
                      name: Yup.string().required("Required"),
                    })}
                    onSubmit={async (values, { setSubmitting, resetForm }) => {
                      setSubmitting(true);
                      const filterColor = colors.filter((item) => {
                        return item.id == activeColor;
                      });
                      if (filterColor && filterColor.length > 0) {
                        values.color = filterColor[0].code;
                        addGroupData(values);
                        setAddGroupModalIsOpen(false);
                        setGroupModalIsOpen(true);
                        resetForm();
                        setSubmitting(false);
                      }
                    }}
                  >
                    {(formik) => {
                      console.log(formik);
                      return (
                        <Form>
                          <div className="row">
                            <div className="col-md-12">
                              <TextInput label="Name" name="name" type="text" />
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-12">
                              <p className="form-label-text"> Group Color </p>
                              <div>
                                <div className="color-box">
                                  {colors &&
                                    colors.map((item) => {
                                      return (
                                        <div>
                                          <div
                                            className={`icon-box ${item.name}`}
                                            onClick={() =>
                                              setActiveColor(item.id)
                                            }
                                          >
                                            {item.id === activeColor && (
                                              <i className="fa fa-check"></i>
                                            )}
                                          </div>
                                        </div>
                                      );
                                    })}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="footer-section">
                            <button type="submit" className="main-button">
                              <i className="fa fa-plus" /> CREATE NEW GROUP
                            </button>
                          </div>
                        </Form>
                      );
                    }}
                  </Formik>
                </div>
              </div>
            </div>
          ) : (
            <div>Loading...</div>
          )}
        </div>
      </Modal>
    </div>
  );
}

export default AddGroupModal;
