import { Formik, Form } from "formik";
import React from "react";
import Modal from "react-modal";
import { TextInput } from "../Form/Form";
import * as Yup from "yup";
import { useState } from "react";
import { useHandleSidebarFilterModal } from "../../shared/hooks/UseModal";
import SidebarFilter from "../common/SidebarFilter";

function SidebarModal({ SIDEBAR_OPTIONS, dropdown_options }) {
  const [isSidebarFilterOpen, setIsSidebarFilterOpen] =
    useHandleSidebarFilterModal();
  return (
    <div>
      <Modal
        isOpen={isSidebarFilterOpen}
        onRequestClose={() => setIsSidebarFilterOpen(false)}
        contentLabel="Modal"
        className="Modal"
        overlayClassName="Overlay"
      >
        <div className="modal-main-header">
          <div className="d-flex justify-content-between">
            <div>
              <div className="modal-main-title">Filters</div>
            </div>
            <div>
              <button
                type="button"
                className="btn-close"
                onClick={() => setIsSidebarFilterOpen(false)}
              />
            </div>
          </div>
        </div>
        <div>
          <SidebarFilter
            SIDEBAR_OPTIONS={SIDEBAR_OPTIONS}
            dropdown_options={dropdown_options}
            col={12}
          />
        </div>
        <div>
          <div className="footer-section">
            <button
              className="main-button"
              onClick={() => setIsSidebarFilterOpen(false)}
            >
              <i className="fa fa-filter" /> Apply Filter
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default SidebarModal;
