import React from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { URI } from "../../domain/constant";

function Header() {
  const data = useSelector((state) => state.auth);
  // console.log("DATA", data);
  const { user } = data;
  return (
    <div>
      {/* ...:::: Start Header Section:::... */}
      <header>
        <div className="header-section">
          <div className="container-fluid">
            <div className="header-wrapper center">
              <div className="row">
                <div className="col-md-9">
                  <div className="logo-navbar">
                    <div className="header-logo">
                      <Link to="/">
                        <img src="/logo.png" alt="LEDSAK Logo" />
                      </Link>
                    </div>
                    <div className="header-navbar ml-20">
                      <ul>
                        <li>
                          <Link to="/dashboard">Client</Link>
                        </li>
                        <li>
                          <Link to="/content">Content</Link>
                        </li>
                        <li>
                          <Link to="/team">Team</Link>
                        </li>
                        <li>
                          <Link to="/integration">Integrations</Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="user-profile pt-10">
                    <Link to="/profile">
                      <img
                        src={
                          user && user.profile_image
                            ? `${URI}${user.profile_image}`
                            : "/assets/images/placeholder.png"
                        }
                        alt="user-logo"
                      />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
    </div>
  );
}

export default Header;
