import React, { useState } from "react";
import Footer from "../../components/footer/Footer";
import MainTable from "../../components/main-table/MainTable";
import Header from "../../components/common/Header";
import Navbar from "../../components/common/Navbar";
import {
  useAllGroupModal,
  useAddClientModal,
  useHandleSidebarFilterModal,
} from "../../shared/hooks/UseModal";
import { useGetDropdownOptions } from "../../shared/hooks/UseClient";
import { MENU, SIDEBAR_OPTIONS } from "../../shared/enums/client_enum";
import SidebarModal from "../../components/modals/SidebarModal";

function Home() {
  const [is_all_group_modal_open, openAllGroupModal] = useAllGroupModal();
  const [isAddClientModalOpen, setAddClientModalIsOpen] = useAddClientModal();
  const [isSidebarFilterOpen, setIsSidebarFilterOpen] =
    useHandleSidebarFilterModal();
  const [dropdown_options] = useGetDropdownOptions();
  return (
    <div>
      <Header />
      <Navbar menu={MENU}>
        <div className="d-flex">
          <div className="bread-crumb-btn">
            <button
              type="button"
              className="button-31"
              style={{ background: "rgb(242, 20, 35)" }}
              onClick={() => setIsSidebarFilterOpen(true)}
            >
              <i className="fa fa-filter pr-10" />
              Filters
            </button>
          </div>
          <div className="bread-crumb-btn">
            <button
              type="button"
              className="button-31"
              style={{ background: "#333" }}
              onClick={() => openAllGroupModal(true)}
            >
              <i className="fa fa-users pr-10" />
              Groups
            </button>
          </div>
          <div className="bread-crumb-btn">
            <button
              type="button"
              className="button-31"
              onClick={() => setAddClientModalIsOpen(true)}
            >
              <i className="fa fa-plus pr-10" />
              Add New Client
            </button>
          </div>
        </div>
      </Navbar>

      <MainTable />
      <SidebarModal
        dropdown_options={dropdown_options}
        SIDEBAR_OPTIONS={SIDEBAR_OPTIONS}
      />
      <Footer />
    </div>
  );
}

export default Home;
