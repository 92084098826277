import { Form, Formik } from "formik";
import { TextInput } from "../../components/Form/Form";
import * as Yup from "yup";
import React from "react";
import Header from "../../components/common/Header";
import { useDispatch, useSelector } from "react-redux";
import { changePassword, updateProfile } from "../../store/actions/auth";
import { Link, useHistory } from "react-router-dom";

function ChangePassword() {
  const history = useHistory();
  const dispatch = useDispatch();
  const data = useSelector((state) => state.auth);
  console.log("DATA", data);
  const { user } = data;
  return (
    <div>
      <Header />
      {user && (
        <section className="mt-120">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="d-flex-3">
                  <div className="profile-box">
                    <div className="profile-form">
                      <h3> Change Password </h3>
                      <Formik
                        initialValues={{
                          password: "",
                          newPassword: "",
                          confirmPassword: "",
                        }}
                        validationSchema={Yup.object({
                          password: Yup.string().required("Required"),
                          newPassword: Yup.string().required("Required"),
                          confirmPassword: Yup.string().required("Required"),
                        })}
                        onSubmit={async (
                          values,
                          { setSubmitting, resetForm }
                        ) => {
                          setSubmitting(true);
                          await dispatch(changePassword(values));
                          history.push("/profile");
                          resetForm();
                          setSubmitting(false);
                        }}
                      >
                        {(formik) => {
                          console.log(formik);
                          return (
                            <Form>
                              <div className="row">
                                <div className="col-md-12">
                                  <TextInput
                                    label="Old Password"
                                    name="password"
                                    type="password"
                                  />
                                </div>
                                <div className="col-md-12">
                                  <TextInput
                                    label="New Password"
                                    name="newPassword"
                                    type="password"
                                  />
                                </div>
                                <div className="col-md-12">
                                  <TextInput
                                    label="Confirm Password"
                                    name="confirmPassword"
                                    type="password"
                                  />
                                </div>

                                <div className="pt-20">
                                  <button
                                    type="submit"
                                    className=" button-31 p"
                                  >
                                    <i className="fa fa-save"></i> Change
                                    Password
                                  </button>
                                </div>
                                <div className="pt-20">
                                  <Link
                                    className="btn     btn-block btn-danger"
                                    style={{ width: "100%" }}
                                    to="/profile"
                                  >
                                    <i className="fa fa-user"></i> Profile
                                  </Link>
                                </div>
                              </div>
                            </Form>
                          );
                        }}
                      </Formik>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      )}
    </div>
  );
}

export default ChangePassword;
