import React, { useState } from "react";
import { useEffect } from "react";
import Modal from "react-modal";

import { useSelectAllGroup } from "../../shared/hooks/UseGroup";
import { useUpdateClient } from "../../shared/hooks/UseClient";
import { useAssignGroupClientModal } from "../../shared/hooks/UseModal";
function AssignGroupModal({ client }) {
  const [groupData, searchValue, setSearchValue] = useSelectAllGroup();

  const { all_groups_loading, all_groups } = groupData;
  const [isAssignGroupModalOpen, setIsAssignGroupModalOpen] =
    useAssignGroupClientModal();
  const [activeGroups, setActiveGroups] = useState(null);
  useEffect(() => {
    if (client && client.groups && client.groups.length > 0) {
      const mappedData = client.groups.map((item) => {
        return item._id;
      });
      setActiveGroups(mappedData);
    } else {
      setActiveGroups([]);
    }
  }, [client]);
  console.log(activeGroups);
  const [updateGroups] = useUpdateClient();
  return (
    <div>
      <Modal
        isOpen={isAssignGroupModalOpen}
        onRequestClose={() => setIsAssignGroupModalOpen(false)}
        contentLabel="Modal"
        className="Modal"
        overlayClassName="Overlay"
      >
        <div className="modal-main-header">
          <div className="d-flex justify-content-between">
            <div>
              <div>Assign Groups</div>
            </div>
            <div>
              <button
                type="button"
                className="btn-close"
                onClick={() => setIsAssignGroupModalOpen(false)}
              />
            </div>
          </div>
        </div>
        {client && activeGroups && (
          <div>
            <div>
              <div>
                <div className="form-container">
                  <div className="form">
                    <i className="fa fa-search" />
                    <input
                      type="text"
                      className="form-control form-input"
                      placeholder="Search Groups"
                      value={searchValue}
                      onChange={(e) => {
                        setSearchValue(e.target.value);
                      }}
                    />
                  </div>
                  <hr />
                  {all_groups &&
                    all_groups.map((item) => {
                      return (
                        <div
                          className="modal-groups-list "
                          style={{
                            marginBottom: "10px",
                            borderBottom: "1px solid #f1f1f1",
                            paddingBottom: "10px",
                            justifyContent: "flex-start",
                            gap: "10px",
                          }}
                        >
                          <input
                            type="checkbox"
                            defaultChecked={activeGroups.includes(item._id)}
                            onChange={(e) => {
                              if (e.target.checked) {
                                setActiveGroups([...activeGroups, item._id]);
                              } else {
                                setActiveGroups(
                                  activeGroups.filter(
                                    (group) => item._id !== group
                                  )
                                );
                              }
                            }}
                            className="form-check-input"
                          ></input>
                          <div
                            className="group-list-text color1"
                            style={{ backgroundColor: item.color }}
                          >
                            <span className="color-name">{item.name}</span>
                          </div>
                        </div>
                      );
                    })}
                  <div className="footer-section">
                    <button
                      className="main-button"
                      onClick={async () => {
                        await updateGroups(client._id, {
                          groups: activeGroups,
                        });
                        setIsAssignGroupModalOpen(false);
                      }}
                    >
                      <i className="fa fa-save" /> SAVE
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </Modal>
    </div>
  );
}

export default AssignGroupModal;
