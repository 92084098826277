import { combineReducers } from "redux";
import alert from "./alert_reducer";
import auth from "./auth_reducer";
import { group_reducer } from "./group_reducer";
import { modal_reducer } from "./modal_reducer";
import { client_reducer } from "./client_reducer";
import { activity_reducer } from "./activity_reducer";

export default combineReducers({
  alert,
  auth,
  group: group_reducer,
  modal: modal_reducer,
  client: client_reducer,
  activity: activity_reducer,
});
