export const GET_GROUPS_STATED = "GET_GROUPS_STATED";
export const GET_GROUPS = "GET_GROUPS";
export const GET_GROUPS_ENDED = "GET_GROUPS_ENDED";
export const ADD_GROUP_STATED = "ADD_GROUP_STARTED";
export const ADD_GROUP = "ADD_GROUP";
export const ADD_GROUP_ENDED = "ADD_GROUP_ENDED";
export const EDIT_GROUP_STATED = "EDIT_GROUP_STATED";
export const EDIT_GROUP = "EDIT_GROUP";
export const EDIT_GROUP_ENDED = "EDIT_GROUP_ENDED";
export const GET_GROUP = "GET_GROUP";
export const GET_GROUP_STATED = "GET_GROUP_STATED";
export const GET_GROUP_ENDED = "GET_GROUP_ENDED";
export const RESET_GROUP = "RESET_GROUP";
export const ERROR_GROUP = "ERROR_GROUP";
export const GET_ALL_GROUPS_STATED = "GET_ALL_GROUPS_STATED";
export const GET_ALL_GROUPS = "GET_ALL_GROUPS";
export const GET_ALL_GROUPS_ENDED = "GET_ALL_GROUPS_ENDED";
