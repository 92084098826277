import React, { useEffect } from "react";
// Import css files
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "react-loading-skeleton/dist/skeleton.css";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { Provider } from "react-redux";
import store from "./store/store";
import Home from "./containers/home/Home";
import { loadUser } from "./store/actions/auth";
import setAuthToken from "./domain/setAuthToken";
import PageNotFound from "./containers/notfound/PageNotFound";
import Content from "./containers/content/Content";
import Team from "./containers/team/Team";
import Integration from "./containers/integrations/Integration";
import Signup from "./containers/signup/Signup";
import Signin from "./containers/signin/Signin";
import Profile from "./containers/profile/Profile";
import LeadAssigned from "./containers/team/LeadAssigned";
import NewLead from "./containers/home/NewLead";
import BeforeLoginRoutes from "./shared/Routes/BeforeLoginRoutes";
import PrivateRoutes from "./shared/Routes/PrivateRoutes";
import ChangePassword from "./containers/profile/ChangePassword";
import AllFollowups from "./containers/follow-ups/AllFollowups";
import RecentlyActive from "./containers/recently-active/RecentlyActive";

function App() {
  useEffect(() => {
    //First we have to bring(get that) token, which is saved in local storage
    const token = localStorage.getItem(process.env.REACT_APP_TOKEN);
    //then we will pass that token in setAuthToken method
    setAuthToken(token);
    store.dispatch(loadUser());
  }, []);

  return (
    <Provider store={store}>
      <Router>
        <Switch>
          <BeforeLoginRoutes exact path="/" component={Signup} />
          <BeforeLoginRoutes exact path="/signup" component={Signup} />
          <BeforeLoginRoutes exact path="/login" component={Signin} />
          <PrivateRoutes exact path="/dashboard" component={Home} />

          <PrivateRoutes exact path="/clients/new-leads" component={NewLead} />
          <PrivateRoutes
            exact
            path="/clients/recently-active"
            component={RecentlyActive}
          />
          <PrivateRoutes
            exact
            path="/clients/follow-ups"
            component={AllFollowups}
          />
          <PrivateRoutes exact path="/content" component={Content} />
          <PrivateRoutes exact path="/team" component={Team} />
          <PrivateRoutes exact path="/integration" component={Integration} />

          <PrivateRoutes exact path="/profile" component={Profile} />
          <PrivateRoutes
            exact
            path="/change-password"
            component={ChangePassword}
          />
          <PrivateRoutes exact path="/lead-assigned" component={LeadAssigned} />

          <Route component={PageNotFound} />
        </Switch>
      </Router>
    </Provider>
  );
}

export default App;
