export const MENU = [
  {
    title: "All Clients",
    link: "/dashboard",
  },
  {
    title: "New Leads",
    link: "/clients/new-leads",
  },
  {
    title: "Follow Ups",
    link: "/clients/follow-ups",
  },
  {
    title: "Recently Active",
    link: "/clients/recently-active",
  },
  {
    title: "Orders",
    link: "/clients/orders",
  },
];

export const SIDEBAR_OPTIONS = [
  {
    id: "name",
    field: "name",
    label: "Name",
    type: "string",
    search_type: "search",
    inputType: "text",
    condition: "",
  },
  {
    id: "email",
    field: "email",
    label: "Email",
    type: "string",
    search_type: "search",
    inputType: "text",
    condition: "",
  },
  {
    id: "phone",
    field: "phone",
    label: "Phone",
    type: "string",
    search_type: "search",
    inputType: "text",
    condition: "",
  },
  {
    id: "source",
    field: "source",
    label: "Source",
    type: "select",
    search_type: "exact",
    inputType: "text",
    condition: "",
    options: [
      { label: "Facebook", value: "facebook" },
      { label: "Google", value: "google" },
      { label: "Instagram", value: "instagram" },
      { label: "Website", value: "website" },
      { label: "Walk In", value: "walk-in" },
      { label: "Influencer", value: "influencer" },
    ],
  },
  {
    id: "page_source",
    field: "page_source",
    label: "Page Source",
    type: "string",
    search_type: "search",
    inputType: "text",
    condition: "",
  },
  {
    id: "groups",
    field: "groups",
    label: "Groups",
    type: "related",
    search_type: "search",
    inputType: "text",
    condition: "",
  },
  {
    id: "assigned_to",
    field: "assigned_to",
    label: "Assigned To",
    type: "string",
    search_type: "search",
    inputType: "text",
    condition: "",
  },
  {
    id: "from_date",
    field: "createdAt",
    label: "From Date",
    type: "string",
    search_type: "conditional",
    inputType: "date",
    condition: "$gte",
  },
  {
    id: "to_date",
    field: "createdAt",
    label: "To Date",
    type: "string",
    search_type: "conditional",
    inputType: "date",
    condition: "$lte",
  },
];
