import moment from "moment";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useAddActivityModal } from "../../shared/hooks/UseModal";

function Timeline({ client }) {
  const [activityTimeline, setActivityTimeline] = useState([]);
  const [isAddActivityModalOpen, setIsAddActivityModalOpen] =
    useAddActivityModal();
  useEffect(() => {
    if (client.activities && client.activities.length > 0) {
      setActivityTimeline(client.activities.reverse());
    } else {
      setActivityTimeline([]);
    }
  }, [client]);
  return (
    <div className="details-card-section bg-off-white-1 border-shadow">
      <div className="section-title">
        <h4>Timeline:</h4>
      </div>
      <div className="row">
        <div className="col-12">
          <div
            className="details-card-details"
            type="button"
            data-bs-toggle="modal"
            data-bs-target="#exampleModal"
          >
            <div className="timeline-card">
              <div className="timeline-icon">
                <div className="phone-icon purple-icon">
                  <i className="fa fa-plus" />
                </div>
              </div>
              <div
                onClick={() => setIsAddActivityModalOpen(true)}
                className="details-title pl-20"
                data-activity-modal-open={isAddActivityModalOpen}
              >
                Add Activity
              </div>
              {/* Modal */}
            </div>
          </div>
          <hr />
          {activityTimeline &&
            activityTimeline.map((item) => {
              return (
                <>
                  <div className="details-card-details mt-16">
                    <div className="timeline-card">
                      <div className="timeline-icon">
                        <div className="phone-icon purple-icon">
                          <i
                            className={
                              item.type_of_activity &&
                              item.type_of_activity === "phone"
                                ? "fa fa-phone"
                                : item.type_of_activity === "message"
                                ? "fa fa-envelope"
                                : item.type_of_activity === "meeting"
                                ? "fa fa-calendar"
                                : item.type_of_activity === "note"
                                ? "fa fa-file-text"
                                : "fa fa-edit"
                            }
                          />
                        </div>
                      </div>
                      <div className="d-flex">
                        <div className="timeline-info-date pl-20">
                          {item.createdAt &&
                            moment(item.createdAt).format(
                              "MMM DD, YYYY hh:m:ss A"
                            )}
                        </div>
                      </div>
                    </div>
                    <div className="details-title pl-50">
                      {item.type_of_activity &&
                        item.type_of_activity.toUpperCase()}
                      <br />
                      <span> {item.notes} </span>
                    </div>
                  </div>
                  <hr />
                </>
              );
            })}
        </div>
      </div>
    </div>
  );
}

export default Timeline;
