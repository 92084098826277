import {
  ADD_GROUP_MODAL,
  ALL_GROUP_MODAL,
  DELETE_GROUP_MODAL,
  ADD_CLIENT_MODAL,
  EDIT_CLIENT_MODAL,
  ASSIGN_GROUP_CLIENT_MODAL,
  ADD_ACTIVITY_MODAL,
  EDIT_ACTIVITY_MODAL,
  SIDEBAR_FILTERS_MODAL,
} from "../types/modal_type";

export const handleAddGroupModal = (formData) => async (dispatch, getState) => {
  dispatch({
    type: ADD_GROUP_MODAL,
    payload: formData,
  });
};
export const handleAllGroupModal = (formData) => async (dispatch, getState) => {
  dispatch({
    type: ALL_GROUP_MODAL,
    payload: formData,
  });
};
export const handleDeleteGroupModal =
  (formData) => async (dispatch, getState) => {
    dispatch({
      type: DELETE_GROUP_MODAL,
      payload: formData,
    });
  };

export const handleAddClientModal =
  (formData) => async (dispatch, getState) => {
    dispatch({
      type: ADD_CLIENT_MODAL,
      payload: formData,
    });
  };

export const handleEditClientModal =
  (formData) => async (dispatch, getState) => {
    dispatch({
      type: EDIT_CLIENT_MODAL,
      payload: formData,
    });
  };
export const handleAssignGroupClientModal =
  (formData) => async (dispatch, getState) => {
    dispatch({
      type: ASSIGN_GROUP_CLIENT_MODAL,
      payload: formData,
    });
  };

export const handleAddActivityModal =
  (formData) => async (dispatch, getState) => {
    dispatch({
      type: ADD_ACTIVITY_MODAL,
      payload: formData,
    });
  };

export const handleEditActivityModal =
  (formData) => async (dispatch, getState) => {
    dispatch({
      type: EDIT_ACTIVITY_MODAL,
      payload: formData,
    });
  };
export const handleSidebarFilterModal =
  (formData) => async (dispatch, getState) => {
    dispatch({
      type: SIDEBAR_FILTERS_MODAL,
      payload: formData,
    });
  };
