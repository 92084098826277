import React, { useState } from "react";
import TableNav from "../../components/table-nav/TableNav";
import Header from "../../components/common/Header";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

function LeadAssigned() {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [toggleTable, setToggleTable] = useState(false);
  return (
    <div>
      <Header />
      <TableNav
        addButtonTitle={`Invite New Members`}
        nav1={"Team Members"}
        nav2={"Invite New Member"}
        link1="/team"
        link2="/lead-assigned"
      />

      <section className="teams mt-15">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <div className="team-members-table fs-14">
                <table class="table table-hover">
                  <thead>
                    <tr>
                      <th scope="col">S No.</th>
                      <th scope="col">Name</th>
                      <th scope="col">Status</th>
                      <th scope="col">Permissions</th>
                      <th scope="col">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td scope="row">
                        <div className="table-serial-no">1.</div>
                      </td>
                      <td>
                        Anandita
                        <div>sumit@luhaifdigitech.com</div>
                      </td>
                      <td>
                        <div className="table-status-icon-text">
                          <div className="table-status">
                            <i className="fa fa-check"></i>
                            <span className="pl-3">Active</span>
                          </div>
                        </div>
                      </td>
                      <td>
                        View Clients (Self, Unassigned)
                        <div>Add & Edit Content, Add & Edit Groups</div>
                      </td>
                      <td>
                        <div className="vertical-dots-icons">
                          <i className="fa fa-ellipsis-v"></i>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td scope="row">
                        <div className="table-serial-no">1.</div>
                      </td>
                      <td>
                        Mamta
                        <div>m@luhaifdigitech.com</div>
                      </td>
                      <td>
                        <div className="table-status-icon-text">
                          <div className="table-status">
                            <i className="fa fa-check"></i>
                            <span className="pl-3">Active</span>
                          </div>
                        </div>
                      </td>
                      <td>
                        View Clients (Self, Unassigned)
                        <div>Add & Edit Content, Add & Edit Groups</div>
                      </td>
                      <td>
                        <div className="vertical-dots-icons">
                          <i className="fa fa-ellipsis-v"></i>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td scope="row">
                        <div className="table-serial-no">1.</div>
                      </td>
                      <td>
                        Taiban
                        <div>tai@luhaifdigitech.com</div>
                      </td>
                      <td>
                        <div className="table-status-icon-text">
                          <div className="table-status-deactivated">
                            <i className="fa fa-times"></i>
                            <span className="pl-3">Deactivated</span>
                          </div>
                        </div>
                      </td>
                      <td>
                        View Clients (Self, Unassigned)
                        <div>Add & Edit Content, Add & Edit Groups</div>
                      </td>
                      <td>
                        <div className="vertical-dots-icons">
                          <i className="fa fa-ellipsis-v"></i>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className="teams-table-footer-text">
        To learn more about using <span>LLM</span> with your team, check out our
        Team Features Help Guide
      </div>
    </div>
  );
}

export default LeadAssigned;
