import moment from "moment";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";

function ClientCard({ active, client, setActiveClient }) {
  const [initials, setInitials] = useState("");
  useEffect(() => {
    if (client) {
      setInitials(getInitials(client.name));
    }
  }, [client]);
  var getInitials = function (string) {
    var names = string.split(" "),
      initials = names[0].substring(0, 1).toUpperCase();

    if (names.length > 1) {
      initials += names[names.length - 1].substring(0, 1).toUpperCase();
    }
    return initials;
  };
  return (
    <div
      className={`contact-card ${active && "active"}`}
      onClick={() => setActiveClient(client)}
    >
      <div className="name-abbr">
        <div className="name-title">{initials}</div>
      </div>
      <div className="contact-card-details">
        <div
          className="d-flex justify-content-between"
          style={{ flexWrap: "wra" }}
        >
          <div className="person-card">
            <div className="person-name">{client.name}</div>
            <div className="status-badge">
              <div className="green-badge"> {client.source} </div>
            </div>
          </div>
          <div className="card-date">
            {client.createdAt && moment(client.createdAt).format("DD-MM-YYYY")}
          </div>
        </div>

        <div className="contact-info leads-card-info">{client.phone}</div>
        <div className="contact-info leads-card-info">{client.notes}</div>
        <hr className="leads-card-divider" />
        <div className="leads-card-footer-data">
          <div className="d-flex " style={{ flexWrap: "wrap", gap: "10px" }}>
            {client.groups &&
              client.groups.map((item) => {
                return (
                  <div
                    className="modal-groups-list "
                    style={{
                      borderBottom: "1px solid #f1f1f1",
                    }}
                  >
                    <div
                      className="group-list-text color1"
                      style={{ backgroundColor: item.color }}
                    >
                      <span className="color-name">{item.name}</span>
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ClientCard;
