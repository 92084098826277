import React from "react";
import { Link } from "react-router-dom";

function TableNav({
  addButtonTitle,
  nav1,
  nav2,
  nav3,
  nav4,
  link1,
  link2,
  link3,
  link4,
}) {
  return (
    <div>
      <section className="bread-crumb-section section-margin">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <div className="bread-crumb">
                <div className="flex-space-between">
                  <div className="bread-crumb-nav">
                    <ul>
                      <li>
                        <Link to={link1}>
                          <div className="nav-title">{nav1}</div>
                        </Link>
                      </li>
                      <li>
                        <Link to={link2}>
                          <div className="nav-title">{nav2}</div>
                        </Link>
                      </li>
                      <li>
                        <Link to={link3}>
                          <div className="nav-title">{nav3}</div>
                        </Link>
                      </li>
                      <li>
                        <Link to={link4}>
                          <div className="nav-title">{nav4}</div>
                        </Link>
                      </li>
                    </ul>
                  </div>

                  <div className="bread-crumb-btn">
                    <button
                      type="button"
                      className="button-31"
                      data-bs-toggle="modal"
                      data-bs-target="#staticBackdrop"
                    >
                      <i className="fa fa-plus pr-10" />
                      {addButtonTitle}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default TableNav;
