export const ADD_GROUP_MODAL = "ADD_GROUP_MODAL";
export const ALL_GROUP_MODAL = "ALL_GROUP_MODAL";
export const DELETE_GROUP_MODAL = "DELETE_GROUP_MODAL";

export const ASSIGN_GROUP_CLIENT_MODAL = "ASSIGN_GROUP_CLIENT_MODAL";
export const ADD_CLIENT_MODAL = "ADD_CLIENT_MODAL";
export const EDIT_CLIENT_MODAL = "EDIT_CLIENT_MODAL";

export const ADD_ACTIVITY_MODAL = "ADD_ACTIVITY_MODAL";
export const EDIT_ACTIVITY_MODAL = "EDIT_ACTIVITY_MODAL";

export const SIDEBAR_FILTERS_MODAL = "SIDEBAR_FILTERS_MODAL";
