import React from "react";
import { Link } from "react-router-dom";

function Navbar({ children, menu }) {
  return (
    <div>
      <section className="bread-crumb-section section-margin">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <div className="bread-crumb">
                <div className="flex-space-between">
                  <div className="bread-crumb-nav">
                    <ul>
                      {menu &&
                        menu.map((item) => {
                          return (
                            <li key={item.title}>
                              <Link to={item.link}>
                                <div className="nav-title">{item.title}</div>
                              </Link>
                            </li>
                          );
                        })}
                    </ul>
                  </div>
                  {children}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Navbar;
