import React from "react";
import {
  useAssignGroupClientModal,
  useEditClientModal,
} from "../../shared/hooks/UseModal";
import Timeline from "./Timeline";

function ClientInfo({ client }) {
  const [isAssignGroupModalOpen, setIsAssignGroupModalOpen] =
    useAssignGroupClientModal();
  const [isEditClientModalOpen, setIsEditClientModalOpen] =
    useEditClientModal();

  return (
    <div className="row">
      <div className="col-8">
        <div className="details-card-section border-shadow">
          <div className="section-title">
            <h4>Clients Info:</h4>
            <button
              className="btn btn-sm"
              onClick={() => setIsEditClientModalOpen(true)}
              data-modal-open={isEditClientModalOpen}
            >
              {" "}
              <i className="fa fa-edit"></i>{" "}
            </button>
          </div>
          <div className="row">
            <div className="col-6">
              <div className="details-card-details">
                <div className="details-card-info">
                  <div className="details-title"> Name</div>
                </div>
                <div className="details-info">
                  {client.name ? client.name : "-"}
                </div>
              </div>
              <hr />
              <div className="details-card-details">
                <div className="details-card">
                  <div className="details-title">Mobile Number</div>
                  <div className="details-icon">
                    <div
                      className="phone-icon"
                      onClick={() =>
                        (window.location.href = `tel:${client.phone}`)
                      }
                    >
                      <i className="fa fa-phone" />
                    </div>
                  </div>
                </div>
                <div className="details-info">
                  {client.phone ? client.phone : "-"}
                </div>
              </div>
              <hr />
              <div className="details-card-details">
                <div className="details-card">
                  <div className="details-title">Whatsapp Number</div>
                  <div className="details-icon">
                    <div
                      className="phone-icon"
                      onClick={() =>
                        window.open(
                          `https://wa.me/91${client.whatsapp_no}`,
                          "_blank"
                        )
                      }
                    >
                      <i className="fa fa-whatsapp" />
                    </div>
                  </div>
                </div>
                <div className="details-info">
                  {client.whatsapp_no ? client.whatsapp_no : "-"}
                </div>
              </div>
              <hr />
              <div className="details-card-details">
                <div className="details-card">
                  <div className="details-title">Email Address</div>
                  <div className="details-icon">
                    <div
                      className="phone-icon"
                      onClick={() =>
                        (window.location.href = `mailto:${client.email}`)
                      }
                    >
                      <i className="fa fa-envelope" />
                    </div>
                  </div>
                </div>
                <div className="details-info">
                  {client.email ? client.email : "-"}
                </div>
              </div>
            </div>
            <div className="col-6">
              <div
                className="details-card-details"
                onClick={() => setIsAssignGroupModalOpen(true)}
                data-modal-open={isAssignGroupModalOpen}
              >
                <div className="details-card">
                  <div className="details-title">Groups</div>

                  <div className="details-icon">
                    <div className="phone-icon">
                      <i className="fa fa-users" />
                    </div>
                  </div>
                </div>
                <div className="details-info groups-green-color">
                  <div
                    className="d-flex "
                    style={{ flexWrap: "wrap", gap: "10px" }}
                  >
                    {client.groups && client.groups.length > 0 ? (
                      client.groups.map((item) => {
                        return (
                          <div
                            className="modal-groups-list "
                            style={{
                              borderBottom: "1px solid #f1f1f1",
                            }}
                            key={item._id}
                          >
                            <div
                              className="group-list-text color1"
                              style={{ backgroundColor: item.color }}
                            >
                              <span className="color-name">{item.name}</span>
                            </div>
                          </div>
                        );
                      })
                    ) : (
                      <div>-</div>
                    )}
                  </div>
                </div>
              </div>
              <hr />
              <div className="details-card-details">
                <div className="details-card">
                  <div className="details-title">Notes</div>
                  {/* <div className="details-icon">
                    <div className="phone-icon">
                      <i className="fa fa-envelope" />
                    </div>
                  </div> */}
                </div>
                <div className="details-info">
                  {client.notes ? client.notes : "-"}
                </div>
              </div>
              <hr />
            </div>
          </div>
        </div>
      </div>
      <div className="col-4">
        <Timeline client={client} />
      </div>
    </div>
  );
}

export default ClientInfo;
