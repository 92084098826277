import React from "react";
import Modal from "react-modal";

import { useSelectAllGroup, useDeleteGroup } from "../../shared/hooks/UseGroup";
import {
  useAllGroupModal,
  useAddGroupModal,
} from "../../shared/hooks/UseModal";
function GroupModal() {
  const [groupData, searchValue, setSearchValue] = useSelectAllGroup();
  const [deleteData, data] = useDeleteGroup();
  const { all_groups_loading, all_groups } = groupData;
  const [isGroupModalOpen, setGroupModalIsOpen] = useAllGroupModal();
  const [isAddGroupModalOpen, setAddGroupModalIsOpen] = useAddGroupModal();

  return (
    <div>
      <Modal
        isOpen={isGroupModalOpen}
        onRequestClose={() => setGroupModalIsOpen(false)}
        contentLabel="Modal"
        className="Modal"
        overlayClassName="Overlay"
      >
        <div className="modal-main-header">
          <div className="d-flex justify-content-between">
            <div>
              <div>Groups</div>
            </div>
            <div>
              <button
                type="button"
                className="btn-close"
                onClick={() => setGroupModalIsOpen(false)}
              />
            </div>
          </div>
        </div>
        <div>
          <div>
            <div>
              <div className="form-container">
                <div className="form">
                  <i className="fa fa-search" />
                  <input
                    type="text"
                    className="form-control form-input"
                    placeholder="Search Groups"
                    value={searchValue}
                    onChange={(e) => {
                      setSearchValue(e.target.value);
                    }}
                  />
                </div>
                <hr />
                {all_groups &&
                  all_groups.map((item) => {
                    return (
                      <div
                        className="modal-groups-list "
                        style={{
                          marginBottom: "10px",
                          borderBottom: "1px solid #f1f1f1",
                          paddingBottom: "10px",
                        }}
                        key={item._id}
                      >
                        <div
                          className="group-list-text color1"
                          style={{ backgroundColor: item.color }}
                        >
                          <span className="color-name">{item.name}</span>
                        </div>
                        <div className="modal-checklist-icon">
                          <div className="check-circle-icon">
                            <div
                              className="fa fa-trash"
                              onClick={() => deleteData(item._id)}
                            />
                          </div>
                        </div>
                      </div>
                    );
                  })}
              </div>

              <div className="footer-section">
                <button
                  className="main-button"
                  onClick={() => setAddGroupModalIsOpen(true)}
                >
                  <i className="fa fa-plus" /> CREATE NEW GROUP
                </button>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default GroupModal;
